@tailwind base;
@tailwind components;
@tailwind utilities;

.hidden-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 4%;

    --card: 0 0% 100%;
    --card-foreground: 240 10% 4%;

    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 4%;

    --primary: 213 83% 57%;
    --primary-foreground: 210 40% 98%;

    --secondary: 47 100% 45%;
    --secondary-foreground: 0 0% 98%;

    --tertiary: 137 78% 43%;

    --muted: 240 7% 96%;
    --muted-foreground: 240 3% 46%;

    --accent: 240 7% 96%;
    --accent-foreground: 240 8% 10%;

    --destructive: 0 78% 59%;
    --destructive-foreground: 0 0% 98%;

    --border: 240 6% 90%;
    --input: 240 6% 90%;
    --ring: 240 8% 10%;

    --radius: 0.5rem;

    /* hsla(32, 91%, 65%, 1) */
    --ex-timestamp-correction: 32 91% 65%;
    /* hsla(4, 77%, 66%, 1) */
    --ex-absence: 4 77% 66%;
    /* hsla(195, 66%, 67%, 1) */
    --ex-paid-time-off: 195 66% 67%;
    /* hsla(303, 70%, 70%, 1) */
    --ex-overtime: 303 70% 70%;
    /* hsla(47, 100%, 45%, 0.8) */
    --ex-schedule: 47 100% 45% / 0.8;
    /* hsla(263, 72%, 67%, 1) */
    --ex-holiday-work: 263 72% 67%;
  }

  .dark {
    --background: 222.2 84% 20%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 20%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 15%;
    --popover-foreground: 210 40% 98%;

    --primary: 213 83% 40%;
    --primary-foreground: 210 40% 98%;

    --secondary: 47 100% 35%;
    --secondary-foreground: 0 0% 98%;

    --muted: 240 7% 50%;
    --muted-foreground: 240 3% 64%;

    --accent: 240 7% 50%;
    --accent-foreground: 240 8% 10%;
    --destructive: 0 78% 40%;
    --destructive-foreground: 0 0% 98%;

    --border: 217.2 32.6% 30%;
    --input: 217.2 32.6% 30%;
    --ring: 212.7 26.8% 50%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@media (max-width: 640px) {
  .container {
    @apply px-4;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
